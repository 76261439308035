import React from 'react'
import { graphql } from 'gatsby'
// import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'

// import BigButton from '../../components/BigButton/BigButton'
import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

import '../../styles/policy.css'

const Toolkits = ({ data }) => {
  const pageData = data.markdownRemark.frontmatter
  return (
    <Layout title={pageData.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={pageData.headerImage.photo} alt={pageData.headerImage.altText}>{pageData.heading}</Header>
      <div className="main">
        <h2>Coming Soon!</h2>
        {/* <div className="center-text">
          {pageData.blurb ? <ReactMarkdown children={pageData.blurb} /> : null}
        </div>
        <div className="flex-row cta-row">
          {pageData.toolkits.length > 0 ? pageData.toolkits.map(toolkit => (
            <BigButton name={toolkit.name} external link={toolkit.link} photo={toolkit.photo} key={toolkit.name} />
          )) : null}
        </div> */}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query ToolkitQuery {
  markdownRemark(frontmatter: {title: {eq: "Toolkits"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      blurb
    }
  }
}
`

export default Toolkits